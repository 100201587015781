<template>
  <div v-if="loading" class="page-container">
    <div class="container-title-block">
      <div class="title-block-text">Курсы</div>
      <div class="title-block-search">
        <SearchInput class="ml-4" v-model="search" label="Поиск курсов" />
      </div>
    </div>
    <div class="container-subtitle-block">
      <v-btn depressed class="button-blue" @click="dialogAddGroup = true">
        Добавить курс
      </v-btn>
      <div class="subtitle-block-count">
        Всего курсов: {{ itemsAll.length }}
      </div>
    </div>
    <div class="container-table">
      <Table
        :items="searchingItems"
        :headers="headers"
        headerFixed
        @click:row="goToDetail"
      >
      </Table>
    </div>
    <Dialog
      v-model="dialogAddGroup"
      refForm="testForm"
      titleText="Добавление курса"
      :mainItems="[
        {
          type: 'text',
          label: 'Название',
          value: this.newItem.name,
          vmodel: (e) => (this.newItem.name = e),
          valid: true,
        },
        {
          type: 'textarea',
          label: 'Описание',
          value: this.newItem.description,
          vmodel: (e) => (this.newItem.description = e),
        },
        {
          type: 'image',
          label: 'Обложка',
          valid: true,
          value: this.newItem.photo,
          vmodel: (e) => (this.newItem.photo = e),
        },
        /*    {
          type: 'video',
          label: 'Трейлер',
          valid: true,
          value: this.newItem.trailer,
          vmodel: (e) => (this.newItem.trailer = e),
        }, */
      ]"
      @click:success="addItem"
      :buttonsFooter="{
        success: {
          title: 'Добавить курс',
          loading: true,
        },
      }"
    >
    </Dialog>
  </div>
</template>
<script>
import SearchInput from "@/components/main/inputs/SearchInput";
export default {
  components: {
    SearchInput,
  },
  data: () => ({
    loading: true,
    search: "",
    items: [],
    itemsAll: [],
    dialogAddGroup: false,
    newItem: {},
    headers: [
      {
        text: "Название",
        align: "flex-left",
        sortable: false,
        value: "name",
      },
      {
        text: "Количество уроков",
        align: "flex-left",
        sortable: false,
        value: "tags",
      },
      {
        text: "Прохождение %",
        align: "flex-left",
        sortable: false,
        value: "",
      },
      {
        text: "Результат %",
        align: "flex-left",
        sortable: false,
        value: "",
      },
    ],
    optionTable: {
      page: 1,
      itemsPerPage: 1000000,
      serverItemsLength: -1,
    },
  }),
  computed: {
    searchingItems() {
      return this.itemsAll.filter((i) =>
        i.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    getItems() {
      this.$axios
        .get(this.$store.getters.apiAdminPanelV4 + "/course/list", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then((res) => {
          this.itemsAll = res.data;
          // this.fiftyItems();
          this.loading = true;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    // fiftyItems() {
    //   if (this.itemsAll.length < 50) {
    //     this.items = this.itemsAll;
    //   } else {
    //     for (let i = 0; i < 50; i++) {
    //       const element = this.itemsAll[i];
    //       this.items.push(element);
    //     }
    //   }
    // },
    // scrollMethod(e) {
    //   const scrollTop = e.target.scrollTop;
    //   const scrollHeight = e.target.scrollHeight - e.target.clientHeight;
    //   if (scrollTop >= scrollHeight - 1000) {
    //     this.sliceConcat();
    //   }
    // },
    // sliceConcat() {
    //   let arrSliced = this.itemsAll.slice(
    //     this.items.length,
    //     this.items.length + 50
    //   );
    //   this.items = this.items.concat(arrSliced);
    // },
    addItem() {
      let formData = new FormData();
      formData.append("name", this.newItem.name);
      formData.append("description", this.newItem.description || "");
      formData.append("finalTestId", this.newItem.finalTestId || "");
      formData.append("photo", this.newItem.photo || "");
      // formData.append("trailer", "trailer");
      formData.append("key", this.newItem.key || "");
      formData.append("isShowcase", this.newItem.isShowcase || "");

      this.$axios
        .post(this.$store.getters.apiAdminPanelV4 + "/course", formData, {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then((res) => {
          this.$router.push({ name: "Course", params: { id: res.data.id } });
          // method()
          // this.dialogTest = false
          // this.getItems()
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    goToDetail(item) {
      this.$router.push({ name: "Course", params: { id: item.id } });
    },
  },
  created() {
    this.getItems();
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  padding: 40px 64px 0px 64px;
  height: 100%;
  width: 100%;
  position: relative;
  .container-title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-block-text {
      color: var(--app-black);
      font-size: 28px;
      font-weight: 600;
      font-style: normal;
      letter-spacing: 0px;
    }
    .title-block-search {
      width: 300px;
      min-width: 300px;
    }
  }
  .container-subtitle-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 40px 0px 12px 0px;
    position: relative;
    .buttons-block-search {
      min-width: 300px;
    }
    .subtitle-block-count {
      font-size: 16px;
      color: var(--app-grey-3);
    }
  }
  .container-table {
    height: calc(100vh - 180px);
    .table-icon {
      filter: invert(44%) sepia(35%) saturate(0%) hue-rotate(179deg)
        brightness(91%) contrast(80%);
      margin: 0px 4px;
    }
  }
}
</style>
